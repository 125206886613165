import logo from './Logo.png';
import Nav from './components/Nav';
import Heading from './components/Heading';
import About from './components/About';
import Aleart from './components/Aleart';
import { useState } from 'react';

function App() {
  const [mode, setMode]= useState('light');
  const [aleart, setAlert]= useState('null');

  const showAlert =(message, type)=>{
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(()=>{
      setAlert('null');
      }, 1500);
  }

  const [darktext, setDarkText]= useState('Dark Mode');

  const toggleMode= ()=>{
    if(mode==='light'){
      setMode('secondary');
      setDarkText('Light Mode')
      document.body.style.backgroundColor='#333';
      showAlert('Dark mode has been enabled', 'success');
    }else{
      setMode('light');
      setDarkText('Dark Mode')
      document.body.style.backgroundColor='white';
      showAlert('Light mode has been enabled', 'success');
    }
  }
  return (
    <>
      <Nav logo={logo} darktext={darktext} contacttext="contact" mode={mode} toggleMode={toggleMode} />
      <Aleart aleart={aleart}/>
      <div className="container">
      <Heading showAlert={showAlert} headingtext={"Enter your Message"} mode={mode} />
      <About mode={mode}/>
      </div>
    </>
  );
}

export default App;
