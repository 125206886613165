import React from 'react'
import '../App.css';
import PropTypes from 'prop-types'


export default function Header(props) {
  return (
    <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}> 

      <div className="container">
        <a className="navbar-brand" href="/"><img src={props.logo} className="App-logo" alt="logo"/></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">Services</a>
            </li>
              <li className="nav-item">
                <a className="nav-link" href="/">{props.contacttext}</a>
              </li>
              <li className="nav-item align-self-center">
              <div className={`form-check form-switch text-${props.mode==='light'?'dark':'light'}`}>

                  <input className="form-check-input" onClick={props.toggleMode} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault"> {props.darktext}</label>
              </div>
              </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes={
  contacttext: PropTypes.string.isRequired,
}

Header.defaultProps = {
  contacttext: 'Contact-us',
}