import React, {useState} from 'react'


export default function TextForm(props) {
  const handleUpClick =()=>{
    // console.log("Uppercase was clicked " + text); 
    let newText = text.toUpperCase();
    setText(newText)
    props.showAlert("Converted to uppercase", "success");
  }

    const handleOnChange = (event)=>{
    // console.log("On change"); 
    setText(event.target.value)
  }
  const [text, setText] = useState('Text Preview');
  // text = "new text"; // Wrong way to change the state
  // setText("new text"); // Correct way to change the state
  return (
    <>
  <div style={{color:props.mode==='dark'?'white':'black'}}>

    <h1>{props.headingtext}</h1>
    <div className="mb-3">
    <textarea className="form-control" value={text} onChange={handleOnChange} id="myBox" rows="8" style={{background:props.mode==='dark'?'gray':'white', color:props.mode==='dark'?'white':'black'}}></textarea>

    </div>
    <button disabled={text.length===0} className="btn btn-primary" onClick={handleUpClick}>Convert to Uppercase</button>
  </div>
  <div className="container mt-5" style={{color:props.mode==='dark'?'white':'black'}}>
    <h3>{text}</h3>

  </div>
  </>
  )
}